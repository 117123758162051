import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import './App.css';
import logo from './images/logo.jpg';
import professionalPreview from './templates/professional.jpg';
import modernPreview from './templates/modern.jpg';
import freshPreview from './templates/fresh.jpg';
import timelinePreview from './templates/timeline.jpg';
import traditionalPreview from './templates/traditional.jpg';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://cvaiwriter-backend-46422a74d7ae.herokuapp.com' 
  : 'http://localhost:3001';

const cvStyles = [
  { 
    id: 'professional',
    name: 'Professional',
    preview: professionalPreview
  },
  { 
    id: 'modern',
    name: 'Modern',
    preview: modernPreview
  },
  { 
    id: 'fresh',
    name: 'Fresh',
    preview: freshPreview
  },
  { 
    id: 'timeline',
    name: 'Timeline',
    preview: timelinePreview
  },
  { 
    id: 'traditional',
    name: 'Traditional',
    preview: traditionalPreview
  }
];

function App() {
  const [cv, setCV] = useState(null);
  const [jobDescription, setJobDescription] = useState(null);
  const [tailoredCV, setTailoredCV] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [dots, setDots] = useState(1);
  const [selectedStyle, setSelectedStyle] = useState('professional');
  const [cvPreview, setCvPreview] = useState(null);
  const [jobPreview, setJobPreview] = useState(null);
  const [isDraggingCV, setIsDraggingCV] = useState(false);
  const [isDraggingJob, setIsDraggingJob] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    let interval;
    if (isGenerating) {
      interval = setInterval(() => {
        setDots(prev => prev === 3 ? 1 : prev + 1);
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isGenerating]);

  const handleGoogleSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    setUser(decoded);
  };

  const handleLogout = () => {
    setUser(null);
  };

  const generatePreview = async (file, setPreviewFunc) => {
    if (file && (file.type === 'application/pdf' || file.type.includes('word'))) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewFunc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = useCallback((event, type) => {
    event.preventDefault();
    event.stopPropagation();
    
    const file = event.dataTransfer.files[0];
    if (file) {
      if (type === 'cv') {
        setCV(file);
        generatePreview(file, setCvPreview);
        setIsDraggingCV(false);
      } else {
        setJobDescription(file);
        generatePreview(file, setJobPreview);
        setIsDraggingJob(false);
      }
    }
  }, []);

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      if (type === 'cv') {
        setCV(file);
        generatePreview(file, setCvPreview);
      } else {
        setJobDescription(file);
        generatePreview(file, setJobPreview);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGenerating(true);
    setIsGenerated(false);
    setError(null);
    const formData = new FormData();
    formData.append('oldcv', cv);  // Updated field name
    formData.append('newjob', jobDescription);  // Updated field name
    formData.append('style', selectedStyle);
    
    try {
      const response = await axios.post(`${API_URL}/api/generate-cv`, formData, {  // Updated URL
        responseType: 'blob',  // Updated to handle direct Word document response
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // Create a preview of the response
      const reader = new FileReader();
      reader.onload = () => {
        setTailoredCV('Generated CV is ready for download');  // Placeholder text since we're now handling direct Word downloads
        setIsGenerated(true);
      };
      reader.readAsText(response.data);

      // Store the blob for download
      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
      });
      const url = window.URL.createObjectURL(blob);
      
      // Trigger download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `CV_${selectedStyle}.docx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to generate CV. ' + (error.response?.data?.error || 'Please try again.'));
    } finally {
      setIsGenerating(false);
    }
};

  return (
    <div className="App">
      <header className="App-header">
        <div className="top-bar">
          <img src={logo} alt="CV AI Writer" className="logo" />
          <div className="auth-section">
            {!user ? (
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            ) : (
              <div className="user-profile">
                <span>Welcome, {user.name}</span>
                <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={`upload-container ${cv && jobDescription ? 'files-loaded' : ''}`}>
            <div 
              className={`upload-box ${isDraggingCV ? 'dragging' : ''} ${cvPreview ? 'has-file' : ''}`}
              onDragOver={(e) => { e.preventDefault(); setIsDraggingCV(true); }}
              onDragLeave={() => setIsDraggingCV(false)}
              onDrop={(e) => handleDrop(e, 'cv')}
            >
              {!cvPreview ? (
                <div className="drag-drop-zone">
                  <div className="box-content">
                    <div className="box-title">
                      <span>Old&nbsp;CV</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, 'cv')}
                    accept=".doc,.docx,.pdf"
                  />
                </div>
              ) : (
                <div className="loaded-message">
                  <span>Old CV Loaded</span>
                  <span className="tick-mark">✓</span>
                </div>
              )}
            </div>

            <div 
              className={`upload-box ${isDraggingJob ? 'dragging' : ''} ${jobPreview ? 'has-file' : ''}`}
              onDragOver={(e) => { e.preventDefault(); setIsDraggingJob(true); }}
              onDragLeave={() => setIsDraggingJob(false)}
              onDrop={(e) => handleDrop(e, 'job')}
            >
              {!jobPreview ? (
                <div className="drag-drop-zone">
                  <div className="box-content">
                    <div className="box-title">
                      <span>New&nbsp;Job</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, 'job')}
                    accept=".doc,.docx,.pdf"
                  />
                </div>
              ) : (
                <div className="loaded-message">
                  <span>New Job Loaded</span>
                  <span className="tick-mark">✓</span>
                </div>
              )}
            </div>
          </div>

          {!cv || !jobDescription ? (
            <div className="upload-instructions">
              Copy and paste, or drag and drop, or browse and upload
            </div>
          ) : (
            <>
              <div className="style-section">
                <h3>Select New CV Style</h3>
                <div className="style-buttons">
                  {cvStyles.map(style => (
                    <div key={style.id} className="style-option">
                      <button
                        type="button"
                        className={`style-button ${selectedStyle === style.id ? 'selected' : ''}`}
                        onClick={() => setSelectedStyle(style.id)}
                      >
                        <img src={style.preview} alt={style.name} />
                      </button>
                      <span>{style.name}</span>
                    </div>
                  ))}
                </div>
              </div>

              <button 
                type="submit" 
                className={`submit-button ${isGenerating ? 'generating' : ''} ${isGenerated ? 'generated' : ''}`}
                disabled={isGenerating}
              >
                {isGenerating ? `Generating${'.'.repeat(dots)}` : isGenerated ? 'Generated and Downloaded' : 'Generate new CV'}
              </button>
            </>
          )}
        </form>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
      </header>
    </div>
  );
}

export default App;